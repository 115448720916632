import './App.css';
import Install from './components/Install';
import Oracle from './components/Oracle'

function App() {

  if (window.ethereum) {
  // return <Crazy />;
  return <Oracle />
} else {
  return <Install />;
}


}
export default App;