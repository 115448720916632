import { ethers, Wallet } from 'ethers';
import test from "../hardhat/artifacts/contracts/test1.sol/Test1.json";
import test2 from "../hardhat/artifacts/contracts/test2.sol/Test2.json";
import { useState } from "react";
import { useEffect } from 'react';




const testCon2 = "0x1B5cCAe6aB308867965f9D1731bb548D275781BC";
const testCon1 = "0xb634CeA909f539CAD75565F7596a1c93dC62ee30";

const provider = new ethers.providers.Web3Provider(window.ethereum);


// get the end user
const signer = provider.getSigner();

const wallet = new Wallet("db8354974f4d9bc17fe188d90c4f9475a3c405483e7b7bb7eef794c379f94ba1", provider)

//get the smart contract 
const testContract1 = new ethers.Contract(testCon1, test.abi, signer);

const testContract2 = new ethers.Contract(testCon2, test2.abi, signer);

const oracle1 = new ethers.Contract(testCon1, test.abi, wallet);

const oracle2 = new ethers.Contract(testCon2, test2.abi, wallet)

function OracleDo() {

    const [test, setTest] = useState([]);
    const [testl, setTestsl] = useState([]);

    const event = async () => {
        const callEvent = await testContract1.ask();
        await callEvent.wait();
    }

    testContract1.on("requestt", (causa, confi) => {            // the listener -- working
        console.log("First parameter :", causa);
        // console.log("Second parameter :", confi);
        oracleSetNget();

    })

    useEffect(() => {
        getNset();
    }, [testl])

    const oracleSetNget = async () => {
        let rand = Math.floor(Math.random() * 100) + 1;
        const settur = await oracle2.settingU(rand);
        await settur.wait()
        .then(() => console.log(settur));
        setTestsl(settur);
    }

    const setNget = async () => {           // triggers useEffect
        let ran = Math.floor(Math.random() * 100) + 1;
        const settings = await testContract2.settingU(ran);
        await settings.wait()
        setTestsl(settings)
    }

    const getNset = async () => {
        const getting = await testContract2.snatchU()

        setTest(getting)
    }

    const responce = async (x) => {
        let rand = Math.floor(Math.random(99)) + 1;

        x.toString();
        const sendInf = await testContract2.sendInfo(1, x, rand);
        await sendInf.wait();
        console.log(rand);
    }

    let kevo = 5;


    function refresh() {
        setTestsl(kevo);
        kevo++;
        console.log(kevo)
    }

    return(
        <>
        <h1>
            <button onClick={event}>Start request</button>
            <button onClick={setNget}>Set Random</button>
        </h1>
        <button onClick={refresh}>refresh</button>
        <h3>Test number: {test}</h3>
        </>
    )


}

export default OracleDo;